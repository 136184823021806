import Vue from 'vue';
import vClickOutside from 'v-click-outside';
import { $setVar } from 'wee-store';
import { $setRef } from 'core/dom';
import { $chain } from 'core/chain';
import $events from 'wee-events';
import 'es6-promise/auto';
import 'lazysizes';
import { disabled, active, invalid } from './classes';
import '../styles/global.scss';

Vue.use(vClickOutside);

// Import all component scss files
require.context('../components', true, /\.scss$/);

$setRef();
$setVar();

$chain({
    /**
     * Set an aria attribute and value
     *
     * @param {string} attr - valid aria attribute
     * @param {string|boolean} [value] - valid aria attribute value
     * @return {this}
     */
    aria(attr, value = null) {
        this.attr(`aria-${attr}`, value);

        return this;
    },

    /**
     * Set the aria attribute 'expanded'
     *
     * @param {boolean} [expand]
     * @returns {this}
     */
    ariaExpand(expand = true) {
        this.aria('expanded', expand);

        return this;
    },

    /**
     * Set the aria attribute 'hidden'
     *
     * @param {boolean} [hidden]
     * @return {this}
     */
    ariaHidden(hidden = true) {
        this.aria('hidden', hidden);

        return this;
    },

    /**
     * Set the tabindex attribute
     *
     * @param {string|function} value - tabindex
     * @returns {object}
     */
    tabindex(value) {
        this.attr('tabindex', value);

        return this;
    },

    /**
     * Add the disabled modifier class
     *
     * @returns {this}
     */
    disable() {
        this.addClass(disabled);

        return this;
    },

    /**
     * Checks if disabled class is present
     *
     * @returns {boolean}
     */
    isDisabled() {
        return this.hasClass(disabled);
    },

    /**
     * Remove the enabled modifier class
     *
     * @returns {this}
     */
    enable() {
        this.removeClass(disabled);

        return this;
    },

    /**
     * Add the active modifier class
     *
     * @returns {this}
     */
    activate() {
        this.addClass(active);

        return this;
    },

    /**
     * Checks if active modifier is present
     * @return {boolean}
     */
    isActive() {
        return this.hasClass(active);
    },

    /**
     * Remove the active modifier class
     * @return {this}
     */
    deactivate() {
        this.removeClass(active);

        return this;
    },

    /**
     * Set an input to invalid
     *
     * @returns {this}
     */
    invalid() {
        return this.addClass(invalid);
    },

    /**
     * Set an input to valid
     *
     * @returns {this}
     */
    valid() {
        return this.removeClass(invalid);
    },

    /**
     * Add an event
     *
     * @param {string} ev - name of event
     * @param {function} callback - callback function to be executed when event is fired
     * @return {this}
     */
    on(ev, callback) {
        $events.on(this, ev, callback);

        return this;
    },
});
