import $router from 'wee-routes';
import './bootstrap';

const global = () => import(/* webpackChunkName: "global" */ '../components/global').then(m => m.default || m);
const navigation = () => import(/* webpackChunkName: "navigation" */ '../components/navigation').then(m => m.default || m);
const hero = () => import(/* webpackChunkName: "hero" */ '../components/heros/hero').then(m => m.default || m);
const news = () => import(/* webpackChunkName: "hero" */ '../components/news/detail').then(m => m.default || m);
const progress = () => import(/* webpackChunkName: "progress-bar" */ '../components/news/progress-bar').then(m => m.default || m);
const catFilter = () => import(/* webpackChunkName: "filter" */ '../components/filter').then(m => m.default || m);
const eventFilter = () => import(/* webpackChunkName: "events" */ '../components/filter/events').then(m => m.default || m);
const tabs = () => import(/* webpackChunkName: "tabs" */ '../components/content/tabs').then(m => m.default || m);
const locationsPage = () => import(/* webpackChunkName: "locations-page" */ '../components/locations-page').then(m => m.default || m);
const locationsVideo = () => import(/* webpackChunkName: "locations-video" */ '../components/locations-video').then(m => m.default || m);
const list = () => import(/* webpackChunkName: "expandable-list" */ '../components/expandable-list').then(m => m.default || m);
const heroVideo = () => import(/* webpackChunkName: "video" */ '../components/heros/video').then(m => m.default || m);
const footer = () => import(/* webpackChunkName: "footer" */ '../components/footer').then(m => m.default || m);
const newsletter = () => import(/* webpackChunkName: "newsletter" */ '../components/newsletter').then(m => m.default || m);
const question = () => import(/* webpackChunkName: "question" */ '../components/faq/question').then(m => m.default || m);
const viewAll = () => import(/* webpackChunkName: "view-all" */ '../components/view-all').then(m => m.default || m);
const back = () => import(/* webpackChunkName: "back" */ '../components/back').then(m => m.default || m);
const preReg = () => import(/* webpackChunkName: "search" */ '../components/pre-reg/search').then(m => m.default || m);
const appt = () => import(/* webpackChunkName: "appt" */ '../components/forms/appt').then(m => m.default || m);
const simpleSearch = () => import(/* webpackChunkName: "simple-search" */ '../components/simple-search').then(m => m.default || m);
const search = () => import(/* webpackChunkName: "search" */ '../components/search').then(m => m.default || m);
const findADoctor = () => import(/* webpackChunkName: "find-a-doctor" */ '../components/find-a-doctor').then(m => m.default || m);
const iWantTo = () => import(/* webpackChunkName: "i-want-to" */ '../components/i-want-to').then(m => m.default || m);
const findAService = () => import(/* webpackChunkName: "find-a-service" */ '../components/find-a-service').then(m => m.default || m);
const validate = () => import(/* webpackChunkName: "validation" */ '../components/forms/validation').then(m => m.default || m);
const submit = () => import(/* webpackChunkName: "submit" */ '../components/forms/submit').then(m => m.default || m);
const findAResearcherSearch = () => import(/* webpackChunkName: "find-a-researcher-search" */ '../components/find-a-researcher-search').then(m => m.default || m);
const findAResearcher = () => import(/* webpackChunkName: "find-a-researcher" */ '../components/find-a-researcher').then(m => m.default || m);
const findATrial = () => import(/* webpackChunkName: "find-a-trial" */ '../components/find-a-trial').then(m => m.default || m);
const file = () => import(/* webpackChunkName: "file" */ '../components/forms/file').then(m => m.default || m);
const addToCalendar = () => import(/* webpackChunkName: "add-to-calendar" */ '../components/add-to-calendar').then(m => m.default || m);
const alertBar = () => import(/* webpackChunkName: "alert-bar" */ '../components/alert-bar').then(m => m.default || m);
const howWeSeeIt = () => import(/* webpackChunkName: "how-we-see-it" */ '../components/pages/how-we-see-it').then(m => m.default || m);

const test = 'test'; // eslint-disable-line

const common = [
    global,
    navigation,
    tabs,
    list,
    footer,
    newsletter,
    simpleSearch,
    search,
    alertBar,
    findAResearcherSearch,
];
const form = [submit, validate];

const paginated = 'p(\\d+)';

$router.map([
    {
        path: '/',
        handler: [
            ...common,
            hero,
            iWantTo,
        ],
    },
    {
        path: '/contact',
        handler: [
            ...common,
            ...form,
        ],
    },
    {
        path: '/insider',
        handler: [
            ...common,
            catFilter,
            viewAll,
        ],
    },
    {
        path: `/insider/${paginated}`,
        handler: [
            ...common,
            catFilter,
            viewAll,
        ],
    },
    {
        path: '/insider/:article',
        handler: [
            ...common,
            news,
            progress,
        ],
    },
    {
        path: '/news',
        handler: [
            ...common,
            catFilter,
            viewAll,
        ],
    },
    {
        path: `/news/${paginated}`,
        handler: [
            ...common,
            catFilter,
            viewAll,
        ],
    },
    {
        path: '/patient-stories',
        handler: [
            ...common,
            catFilter,
            viewAll,
        ],
    },
    {
        path: `/patient-stories/${paginated}`,
        handler: [
            ...common,
            catFilter,
            viewAll,
        ],
    },
    {
        path: '/patient-stories/:article',
        handler: [
            ...common,
            news,
            progress,
            heroVideo,
        ],
    },
    {
        path: '/services/:service',
        handler: [...common],
    },
    {
        path: '/personnel/:physician',
        handler: [...common, heroVideo],
    },
    {
        path: '/news/:article',
        handler: [
            ...common,
            news,
            progress,
        ],
    },
    {
        path: '/faq',
        handler: [
            ...common,
            catFilter,
            question,
        ],
    },
    {
        path: '/events',
        handler: [
            ...common,
            eventFilter,
        ],
    },
    {
        path: `/events/${paginated}`,
        handler: [
            ...common,
            eventFilter,
        ],
    },
    {
        path: '/events/:event',
        handler: [
            ...common,
            addToCalendar,
        ],
    },
    {
        path: '/classes/:class',
        handler: [
            ...common,
            addToCalendar,
        ],
    },
    {
        path: '/locations',
        handler: [
            ...common,
            locationsPage,
        ],
    },
    {
        path: '/locations/:page',
        handler: [
            ...common,
            locationsVideo,
        ],
    },
    {
        path: '/specialties/:specialty',
        handler: [...common],
    },
    {
        path: '/privacy',
        handler: [...common],
    },
    {
        path: '/patients',
        handler: [...common],
    },
    {
        path: '/pre-registration',
        handler: [...common, preReg],
    },
    {
        path: '/education-research',
        handler: [...common],
    },
    {
        path: '/patients',
        handler: [...common],
    },
    {
        path: '/request-an-appointment',
        handler: [
            ...common,
            appt,
        ],
    },
    {
        path: '/mci-patient-navigator',
        handler: [...common],
    },
    {
        path: '/find-a-doctor',
        handler: [...common, findADoctor],
    },
    {
        path: '/find-a-researcher',
        handler: [...common, findAResearcher],
    },
    {
        path: '/find-a-service',
        handler: [...common, findAService],
    },
    {
        path: '/find-a-trial',
        handler: [...common, findATrial],
    },
    {
        path: '/refer-a-patient',
        handler: [
            ...common,
            appt,
            file,
        ],
    },
    {
        path: '/how-we-see-it',
        handler: [howWeSeeIt],
    },
    // For live preview
    {
        path: '/cms/entries/:section/*',
        handler: [
            ...common,
            news,
            progress,
        ],
    },
])
    .notFound({ handler: [...common, back] })
    .run();
